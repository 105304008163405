import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _Store from '_Store';

interface Props {
    route: {
        auth: boolean;
        path: string;
        types?: string[];
        element: any;
    };
    children: any;
}
const Raute = ({ route, children }: Props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { _store: { _auth: { user, token } } } = React.useContext(_Store);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isAuthorized = route.auth === isLoggedIn;

    React.useEffect(() => {
        if (isLoggedIn) {
            location.pathname.includes('auth') && navigate('/', { replace: true });
        } else {
            !location.pathname.includes('auth') && navigate(`/auth/login`, { replace: true });
        }
        // eslint-disable-next-line 
    }, [route, isAuthorized]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default Raute;