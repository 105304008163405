import React from 'react';
import MaterialIcon from './MaterialIcon';

interface Props {
    icon?: string;
    color: 'warning' | 'success' | 'danger';
    children: any;
    className?: string;
}
const Alert = ({ icon, color, children, className }: Props) => {

    return (
        <React.Fragment>
            <div id="Alert" className={`${icon && 'flex items-center gap-3'} font-medium- ${color} ${className}`}>
                {icon && (
                    <MaterialIcon name={icon} className={`fill ${color}`} />
                )}
                {children}
            </div>
        </React.Fragment>
    );
};

export default Alert;