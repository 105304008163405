import React from 'react';
import { types } from 'helpers';

// ::: auth pages
const Auth = React.lazy(() => import('pages/Auth/_Auth'));

// ::: app pages
const Users = React.lazy(() => import('pages/Users'));
const Account = React.lazy(() => import('pages/Account'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Verification = React.lazy(() => import('pages/Compliance/Document'));
const Verifications = React.lazy(() => import('pages/Compliance/List'));


const routes = [
    { path: `*`, element: Auth, auth: false, },
    { path: `/auth/*`, element: Auth, auth: false, },

    { path: `/`, element: Dashboard, auth: true, },
    { path: `/account/*`, element: Account, auth: true, },
    { path: `/verification`, element: Verification, auth: true, },

    { path: `/users`, element: Users, auth: true, types: [types.UserTypes.ADMIN], },
    { path: `/verifications`, element: Verifications, auth: true, types: [types.UserTypes.ADMIN], },

    { path: `*`, element: Dashboard, auth: true, },
];

export default routes;