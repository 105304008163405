import React from 'react';
import _Store from '_Store';
import { Button, Modal, notification, Spin } from 'antd';
import { CodeInput, LoadingMin } from 'components';
import { axius, helpers, types } from 'helpers';

interface Props {
    open: boolean;
    channel: types.ComplianceChannels;
    username?: string;

    onClose: () => void;
    onSuccess?: (user: types.User) => void;
}
const EnterCode = ({ open, channel, username, onClose, onSuccess }: Props) => {

    const { _store: { _auth: { user } }, _setUser } = React.useContext(_Store);

    const [code, setCode] = React.useState('');
    const [seconds, setSeconds] = React.useState(90);
    const [submitting, setSubmitting] = React.useState(false);

    const uzername = username || user[channel];
    const channelName = channel.split('_')[0];

    React.useEffect(() => {
        if (open) {
            setSeconds(90);
        }
        // eslint-disable-next-line 
    }, [open]);

    React.useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line 
    }, [seconds]);

    React.useEffect(() => {
        if (code.length >= 6) {
            submit();
        }
        // eslint-disable-next-line 
    }, [code]);

    const submit = () => {
        setSubmitting(true);
        axius.put(`users/${user.id}/compliance`, { code: +code, channel, username: uzername }).then(res => {
            if (res.status === 200) {
                _setUser(res.data);
                onSuccess && onSuccess(res.data);
                close();
                notification.success({ message: `${helpers.ucFirst(channelName)} verified`, description: `` });
            } else {
                notification.error({ ...res });
            }
            setSubmitting(false);
        });
    }

    const resend = () => {
        setSubmitting(true);
        const channels = {
            [types.ComplianceChannels.email]: types.OtpChannels.EMAIL,
            [types.ComplianceChannels.phone_number]: types.OtpChannels.SMS,
        };
        axius.post(`otp`, { channel: channels[channel], username: uzername }).then(res => {
            if (res.status === 201) {
                setCode('');
                setSeconds(90);
                notification.success({ ...res });
            } else {
                notification.error({ ...res });
            }
            setSubmitting(false);
        });
    }

    const close = () => {
        onClose();
        setCode('');
        setSeconds(0);
    }

    return (
        <React.Fragment>
            <Modal open={open} width={450} title={null} footer={null} centered onCancel={close}>
                <Spin spinning={submitting} indicator={<div><LoadingMin /></div>}>
                    <div className="py-12 text-center">
                        <div className="mb-2 text-xl xl:text-2xl font-semibold capitalize millik">Verify {channelName}</div>
                        <div className="mb-6">
                            Enter the 6 digit code sent to <span className="primary font-semibold">{uzername}</span> <br />
                            {channel === types.ComplianceChannels.email && (
                                <span className="text-sm text-gray-500">Please check your mail (both inbox, spam or junk) to complete this verification.</span>
                            )}
                        </div>

                        <CodeInput size={6} clear={!code} onChange={setCode} onPressEnter={submit} />
                        <p>&nbsp;</p>
                        <Button size="large" block type="primary" disabled={!!(code.length !== 6)} onClick={submit}>Continue</Button>
                        <p>&nbsp;</p>
                        <div className="text-center text-sm text-gray-500">
                            {seconds > 0 && (
                                <div>Resend in {seconds} seconds</div>
                            )}
                            {seconds === 0 && (
                                <span className="hover:font-medium cursor-pointer" onClick={resend}>Resend now</span>
                            )}
                        </div>
                    </div>
                </Spin>
            </Modal>
        </React.Fragment>
    );
}

export default EnterCode;