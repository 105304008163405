import React from 'react';
import { Form, Input } from 'antd';


interface Props {
    form: any;
    name: string | (string | number)[];
    rules?: any;
    label?: string;
    prefix?: any;
    suffix?: string;
    disabled?: boolean;
    onChange?: (val: string) => void;
    placeholder?: string;
}
const InputNumber = ({ form, name, rules, label, prefix, suffix, disabled, onChange, placeholder }: Props) => {

    return (
        <React.Fragment>
            <Form.Item name={name} rules={rules} label={label} className="mb-0">
                <Input
                    size="large"
                    type="text"
                    disabled={disabled}
                    placeholder={placeholder}
                    prefix={prefix}
                    suffix={suffix}
                    onChange={e => {
                        const val = e.target.value.replace(/[^0-9.+]/g, '');
                        form.setFieldValue(name, val);
                        onChange && onChange(val);
                    }}
                />
            </Form.Item>
        </React.Fragment>
    );
};

export default InputNumber;