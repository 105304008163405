import React from 'react';
import { Spin, Checkbox } from 'antd';
import { LoadingMin } from './Loading';
import { images } from 'helpers';
import Pagination from './Pagination';

interface Props {
    data: any;
    loading: boolean;
    heading: string[];
    pagination?: {
        total: number;
        perPage: number;
        current?: number;
        navigate: (e: number) => void;
    };
    className?: string;
    loadingHeight?: number;

    onCheck?: (ids: string[]) => void;
    onClick?: (row: any) => void;
}
const Table = ({ data, loading, heading, pagination, className, loadingHeight, onCheck, onClick }: Props) => {

    const [checked, setChecked] = React.useState([]);

    React.useEffect(() => {
        // setChecked([]);
        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        onCheck && onCheck(checked);
        // eslint-disable-next-line
    }, [checked]);

    const thClass = 'border-gray-200 px-5 py-3 border-ts text-left font-semibold text-gray-600 text-sm bg-gray-100 first:rounded-tl-xl last:rounded-tr-xl';
    const tdClass = 'border-gray-200 px-5 py-3 text-gray-600- border-t text-sm';

    return (
        <React.Fragment>
            <div className={`bg-white rounded-b-xl rounded-tl-xl rounded-tr-xl border p-4s overflow-x-scroll no-scrollbar ${className}`}>
                {(loading && data.length === 0) ? (
                    <div className="bg-gray-50s rounded-bl-xl rounded-br-xl borders">
                        <Spin spinning={true} indicator={<div className="flex justify-centers items-center"><LoadingMin size={35} /></div>}>
                            <div style={{ height: loadingHeight || 525 }} />
                        </Spin>
                    </div>
                ) : (
                    <div>
                        <Spin spinning={loading} indicator={<div className="flex justify-center items-center h-fit"><LoadingMin size={35} /></div>}>
                            <div id="Table" className="no-scrollbar">
                                <table className="w-full p-4s">
                                    {(data || []).length > 0 && (
                                        <thead className="">
                                            <tr>
                                                {onCheck && (
                                                    <th className={`${thClass} w-[20px]`}>
                                                        <Checkbox
                                                            checked={(data || []).length === checked.length}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setChecked((data || []).map((d: any) => d.id));
                                                                } else {
                                                                    setChecked([]);
                                                                }
                                                            }}
                                                        />
                                                    </th>
                                                )}
                                                {heading.map((th, i) => (
                                                    <th key={`th-${th}-${i}`} className={`${thClass}`}>{th}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                    )}
                                    <tbody className="">
                                        {(data || []).length === 0 && (
                                            <tr>
                                                <td colSpan={15} align="center" className={`${tdClass} border-t-0`} style={{ height: 371 }}>
                                                    <img src={images.EmptyTable} alt="EmptyTable" className="h-[75px]" />
                                                    <div className="text-gray-500 font-ligh mt-2">No data to display</div>
                                                </td>
                                            </tr>
                                        )}
                                        {(data || []).length > 0 && (data || []).map((row: any, i: number) => (
                                            <tr key={`td-${i}`} className={`hover:bg-gray-50 transi ${onClick ? 'cursor-pointer' : ''}`}>
                                                {onCheck && (
                                                    <td className={`${tdClass} w-[20px]`}>
                                                        <Checkbox
                                                            checked={checked.includes(row.id)}
                                                            onChange={e => {
                                                                onCheck(e.target.checked ? [row.id] : []);
                                                                if (e.target.checked) {
                                                                    setChecked(checked.concat(row.id));
                                                                } else {
                                                                    setChecked(checked.filter(che => che !== row.id));
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                )}
                                                {Object.keys(row).map(rowKey => {
                                                    const width = typeof row[rowKey] !== 'object' || (typeof row[rowKey] === 'object' && (typeof row[rowKey].width === 'undefined' || row[rowKey].width));
                                                    const align = typeof row[rowKey] !== 'object' || (typeof row[rowKey] === 'object' && (typeof row[rowKey].align === 'undefined' || row[rowKey].align));
                                                    const clickable = typeof row[rowKey] !== 'object' || (typeof row[rowKey] === 'object' && (typeof row[rowKey].clickable === 'undefined' || row[rowKey].clickable));
                                                    return (
                                                        rowKey !== 'id' && (
                                                            <td
                                                                key={rowKey} width={`${typeof width === 'number' ? `${width}%` : ''}`}
                                                                className={`${tdClass}`} align={align.toString()}
                                                                onClick={() => onClick && clickable && onClick(row)}
                                                            >
                                                                {typeof row[rowKey] === 'object' ? (row[rowKey].value || row[rowKey]) : row[rowKey]}
                                                            </td>
                                                        )
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Spin>
                    </div>
                )}

                {pagination && (
                    <div className="px-4 py-6 border-t">
                        <Pagination
                            total={pagination.total}
                            perPage={pagination.perPage}
                            navigate={pagination.navigate}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Table;