import React from 'react';

interface Props {
    value?: string;
    onChange?: (e: boolean) => void;
}
const PasswordStrength = (props: Props) => {

    const { value } = props;

    const [valid, setValid] = React.useState({ min: false, alpha: false, num: false });

    React.useEffect(() => {
        setValid({
            min: (value || '').length >= 8 ? true : false,
            num: /\d/.test(value || ''),
            alpha: /[a-zA-Z]/.test(value || ''),
        });
    }, [value]);

    React.useEffect(() => {
        props.onChange && props.onChange(Object.values(valid).filter(v => !v).length === 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid]);

    return (
        <React.Fragment>
            <CustomRadio selected={valid.min} label="Must be at least 8 characters" />
            <CustomRadio selected={valid.alpha} label="Must be at least 1 alphabet" />
            <CustomRadio selected={valid.num} label="Must be at least 1 number" />
        </React.Fragment>
    );
};

export default PasswordStrength;


const CustomRadio = ({ label, selected }: { label: string, selected: boolean }) => {

    return (
        <React.Fragment>
            <div className="flex items-center py-1s">
                <span className={`material-symbols-outlined ${selected ? 'fill primary' : ''} text-xl `}>
                    {selected ? 'check_circle' : 'radio_button_unchecked'}
                </span>
                <span className="ml-2 text-base">{label}</span>
            </div>
        </React.Fragment>
    );
}